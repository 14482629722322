import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PrivacyPolicyPage = () => (
  <Layout>
    <SEO title="Privacy policy" keywords={[`nvotes`, `secure`, `online`, `voting`, `tools`, `software`, `system`, `election`, `poll`]} />
      <div>
        <h1>Privacy Policy</h1>
        <div>
          <p>In compliance with the General Data Protection Ruling, “GDPR”, we inform you that the company responsible for processing your data of the website https://nvotes.com/ (hereinafter, “WEBSITE”) is AGORA VOTING SL (hereinafter, “NVOTES”) based in Guzman El Bueno, 11, 6-1 28015, Madrid, Spain and with tax identification number ESB87138467.</p>
          <p>The purpose of the processing is to manage the relationship with CLIENTs, offer information and news about NVOTES and send commercial communications by any means, including electronic means, about NVOTES products and services.</p>
          <p><strong>How can I revoke my consent and exercise my rights?</strong></p>
          <p>1.1 Users are entitled to (i) Access their personal data; (ii) request the rectification of any inaccurate data; (iii) request the erasure of their data; (iv) request that the processing of their data be limited; (v) object to the processing of their data; (vi) exercise their right to be forgotten; and (vii) request the portability of their data. Furthermore, Users will have the right not to be the subject of decisions based solely on automated data processing.</p>
          <p>1.2 In order to exercise said rights, Users can write to the above postal address or email&nbsp;<a href="mailto:legal@nvotes.com">legal@nvotes.com</a>&nbsp;indicating clearly the right to exercise and attaching a copy of ID card or similar documentation.</p>
          <p>1.3 Without prejudice to any other administrative and/or judicial actions which may be at their disposal, Users shall be entitled to file a claim before the Supervisory Authority, in particular in the Member State where they have their usual residence or their workplace or in the place where any alleged infringement has taken place, if they consider that their personal data have not been processed in accordance with applicable regulations and/or if they have been prevented from exercising any of their rights. The Supervisory Authority receiving the claim shall inform the claimant of the situation and result of the same.</p>
          <p>1.4 The CLIENT that has the database in order to make electronic voting in ownership NVOTES platform states that it has collected all the personal data in accordance with the provisions of the GDPR, exonerating any responsibility NVOTES otherwise.</p>
          <p>1.5 For this purpose, NVOTES acts exclusively as data processor, for and on behalf of the CLIENT and in the terms provided in the data protection legislation.</p>
          <p><strong>Confidential Information</strong></p>
          <p>2.1. NVOTES is committed to keep the strictest secret and classified information as confidential. Any data to which NVOTES accesses under this contract shall be deemed to be Confidential Information, the CLIENT or ORGANIZER information and data accessed during the contract’s execution.</p>
          <p>2.2 NVOTES agrees not to disclose such Confidential Information, and not to publish it in any other way, either directly or through third parties or companies, nor to make it available to third parties without the prior written consent of the CLIENT.</p>
          <p>2.3 Similarly, NVOTES undertakes, after the termination of this contract, not to retain any copies of the Confidential Information.</p>
          <p>2.4 The confidentiality obligations set forth in this clause shall be indefinite, remaining in force after the termination, for any reason, the relationship between NVOTES and the CLIENT.</p>
          <p><strong>Data Processor and Data Owner</strong></p>
          <p>3.1 The Client as owner of the personal data declares to have complied with the provisions and guarantees provided by the GDPR and for this purpose declares that the database owned by the CLIENT complies with the regulatory provisions and that the personal data has been collected with the consent of the owners, fulfilling the duty of information and security measures provided for this purpose.</p>
          <p>3.2 NVOTES keep the strictest confidence over personal data that is subject to processing and to which the CLIENT has the status of data controller and to which NVOTES accesses as a data processor.</p>
          <p>3.3 The CLIENT is the only one who will decide on the purpose, use and processing systems of the data accessed by NVOTES who only processes the data as instructed by the CLIENT.</p>
          <p>3.4 NVOTES will not apply or use the data for purposes other than set out in this contract, nor will communicate this data to others. If NVOTES determines to third party companies all or part of the contracted services, NVOTES shall communicate in advance to the CLIENT.</p>
          <p><strong>ARCO Rights Management</strong></p>
          <p>4.1 CLIENT is responsible for the reception, management and response to requests for access, rectification, cancellation and opposition that can present those affected. To this end, all treatments carried out by NVOTES that causes direct, postal or electronic communication must include an indication of the possibility of exercising rights of access, rectification, cancellation and opposition before NVOTES.</p>
          <p>4.2 CLIENT must cancel, update or modify on the platform where necessary the data of those users who have exercised the ARCO rights in platform owned by NVOTES.</p>
          <p>4.3 Given the possibility that an affected party erroneously communicates with NVOTES to exercise its rights, once this event is detected as such by NVOTES, NVOTES agrees to refer to the CLIENT at the earliest the requests for access, rectification, cancellation and opposition received from those affected by the data processing happening on the platform owned by NVOTES of files owned by the CLIENT.</p>
          <p><strong>Post-contractual Information Processing</strong></p>
          <p>5.1. Once fulfilled the contractual provision, the personal data in systems and supports of NVOTES property shall be destroyed or returned to the CLIENT.</p>
          <p>5.2. NVOTES only keeps the data that is necessary to provide evidence to a possible claim for liability arising from its processing. Preservative prior blocking of data will take place, so that its processing is prevented, and its duration may not exceed the period of limitation for such responsibilities. During the data retention period the same security measures as during the processing should be applied.</p>
          <p><strong>Legislation and jurisdiction</strong></p>
          <p>6.1 For any divergence arising from this contract, both parties expressly submit themselves to Spanish law and to the Courts of the City of Madrid.</p>
          <p><strong>Recommendations</strong></p>
          <p>&nbsp;Please read carefully and abide by the following recommendations:</p>
          <p>7.1 This Website is not aimed at children under age. Please activate parental control in order to prevent and control the access of minors to the Internet and inform children under age of security matters.</p>
          <p>7.2 Keep your equipment fitted with duly updated antivirus software against malicious software and spyware applications which may jeopardize your Internet navigation and the information hosted on your equipment.</p>
          <p><strong>Links</strong></p>
          <p>8.1 This Website may include links to other websites. Note that we are not responsible for any data processing which may be performed by the holders of other websites. This Privacy Policy will be applied exclusively to information which we compile on this Website. We recommend that you read the privacy policies of other websites which you may access through links located on our Website or which you may visit otherwise.</p>
          <p><strong>Updates and changes in our Privacy Policy</strong></p>
          <p>9.1 NVOTES reserves the right to make amendments in this Privacy Policy. Said changes will be communicated to the Users through notices posted on the Website or by any other means which may be appropriate.</p>
        </div>
        <p>This document was edited with the free version of the instant HTML converter tool. Try it <a href="https://htmled.it/html-converter/" target="_blank" rel="noopener noreferrer">here</a> and use it every time for your projects.</p>
      </div>

  </Layout>
)

export default PrivacyPolicyPage
